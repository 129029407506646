(function($) {

	$(document).ready(function() {
		var $window = $(window),
			$document = $(document);

		hpNav();

		$(".container").fitVids();

		$('#mobile-content-menu').sidr({
			name: 'sidr-mobile-content',
			source: '#sidr_close, .header_search, #sidr_home, .global-header-nav',
			side: 'left'
		});

		$('.sidr-class-caret').on('click', function(event) {
			event.preventDefault();
			$(this).next('.sidr-class-dropdown').toggleClass('display_block');
			$(this).toggleClass('caret_up');
		});

		$('#sidr-id-close_btn').on('click', function(event) {
			event.preventDefault();
			$.sidr('close', 'sidr-mobile-content'); // close the div with the ID of "sidr"
		});

		$(window).resize(function() {
			$.sidr('close', 'sidr-mobile-content');
		});

		function hpNav() {
			var count = $('.depth--zero > li').length;
			var parentWidth = (count);
			var countToLengthMap = ["one", "two", "three", "four", "five", "six", "seven"];
			var cssLength = "100%";
			if (count > 0) {
				cssLength = countToLengthMap[count - 1];
			}
			$('.depth--zero > li').addClass(cssLength);
			$('.depth--zero').addClass(cssLength);
		}

		$('.accordion_title').on('click', function() {
			$(this).next('.accordion_content').toggleClass('display_block');
			$(this).toggleClass('accordion_open');
		})

		var $feature_list_item = $('.feature_list_item'),
			$feature_image = $('.feature_image');
		$feature_list_item.each(function() {
			$(this).on("mouseenter touchstart", function(event) {
				$feature_list_item.removeClass("active");
				$(this).addClass("active");
				var item_num = $(this).data('img'),
					bg_target = $('.item_' + item_num);
				$feature_image.removeClass('display_block');
				$(bg_target).addClass('display_block');
			});
		});


		$('.related_tires').slick({
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 2,
			slidesToScroll: 2,
			nextArrow: '<span class="slick-next slick-arrow"></span>',
			prevArrow: '<span class="slick-prev slick-arrow"></span>',
			responsive: [{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		});
		$('.featured_gear').slick({
			dots: false,
			infinite: true,
			fade: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			nextArrow: '<span class="slick-next slick-arrow"></span>',
			prevArrow: '',
			responsive: [{
				breakpoint: 1024,
				settings: {
					nextArrow: '<span class="slick-next slick-arrow"></span>',
					prevArrow: '<span class="slick-prev slick-arrow"></span>',
				}
			}]
		});


		$('.athlete__videos--slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			infinite: true,
			adaptiveHeight: true,
			nextArrow: '',
			prevArrow: '',
			responsive: [
				{
			      	breakpoint: 1024,
					settings: {
						arrows: true,
						nextArrow: '<span class="slick-next slick-arrow"></span>',
						prevArrow: '',
					}
			    }

			],
			asNavFor: '.athlete__videos--slider-nav'
		}).on('afterChange', function(e, o) {
			//on change slide = do action
			$('iframe').each(function() {
				$(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
			});
		});
		$('.athlete__videos--slider-nav').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			asNavFor: '.athlete__videos--slider',
			dots: false,
			infinite: true,
			focusOnSelect: true,
			nextArrow: '<span class="slick-next slick-arrow"></span>',
			prevArrow: '',
			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		}).on('afterChange', function(e, o) {
			//on change slide = do action
			$('iframe').each(function() {
				$(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
			});
		});


		var $sliders = $('.slider');
		$sliders.each(function(idx, item) {
			var carouselId = "carousel" + idx;
			this.id = carouselId;
			$(this).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				fade: true,
				adaptiveHeight: true,
				touchMove: false,
				responsive: [{
					breakpoint: 768,
					settings: {
						arrows: true,
						nextArrow: '<span class="slick-next slick-arrow"></span>',
						prevArrow: '<span class="slick-prev slick-arrow"></span>',
					}
				}]
			});
		});

		var $slider_nav = $('.slider-nav');
		$slider_nav.each(function(idx, item) {
			var $slidesToShow = ($(this).parents('.sidebar__module').length) ? 3 : 6;
			$(this).slick({
				slidesToShow: $slidesToShow,
				slidesToScroll: 1,
				asNavFor: '#carousel' + idx,
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				nextArrow: '<span class="slick-next slick-arrow"></span>',
				prevArrow: '<span class="slick-prev slick-arrow"></span>',
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						arrows: false,
					}
				}]
			});
		});

		//Modal Default Options
		$.modal.defaults = {
			closeExisting: true, // Close existing modals. Set this to false if you need to stack multiple modal instances.
			escapeClose: true, // Allows the user to close the modal by pressing `ESC`
			clickClose: true, // Allows the user to close the modal by clicking the overlay
			closeText: '', // Text content for the close <a> tag.
			closeClass: '', // Add additional class(es) to the close <a> tag.
			showClose: true, // Shows a (X) icon/link in the top-right corner
			modalClass: "modal", // CSS class added to the element being displayed in the modal.
			spinnerHtml: null, // HTML appended to the default spinner during AJAX requests.
			showSpinner: true, // Enable/disable the default spinner during AJAX requests.
			fadeDuration: 300, // Number of milliseconds the fade transition takes (null means no transition)
			fadeDelay: 1.0 // Point during the overlay's fade-in that the modal begins to fade in (.5 = 50%, 1.5 = 150%, etc.)
		};

		var $heros = $('.hero');
		if ($heros.length) {
			//Load full background image
			$heros.each(function() {
				var $this = $(this);
				if ($this.hasClass('flex--slider')) {
					return true;
				}

				if ($this.hasClass('hero--has-background')) {
					var $hero_background = $this.find('.hero__background'),
						$full_image = $hero_background.data('fullimage');
					if ($full_image) {
						var $img = $('<img>');
						$img.attr('src', $full_image);
						$img.on('load', function(e) {
							$hero_background.css({
								'background-image': 'url(' + $full_image + ')'
							});
							$hero_background.removeClass('hero__background--blur');
						});
					} else {
						$hero_background.removeClass('hero__background--blur');
					}
				}

				var $video_container = $this.find('.hero__video-container');
				if ($video_container.length) {
					setHeroVideoDimensions($video_container);
					setVideoEvents($video_container);
					getBackgroundVideo($video_container);
					$(window).on('resize', function() {
						setHeroVideoDimensions($video_container);
					});
				}

				$this.find('p').each(function() {
					var $animation = $(this).data('animation');
					$(this).removeClass('hidden');
					if ($animation == 'none') {
						return;
					}
					$(this).animateCss($animation);
				});
			});
		}

		var $flex__slider = $('.flex--slider')
		$flex__slider.slick({
			dots: false,
			//infinite: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			nextArrow: '<span class="slick-next slick-arrow"></span>',
			prevArrow: '<span class="slick-prev slick-arrow"></span>',
		});

		//Country Selector Modal
		$('.CountrySelector').find('.toplink').each(function() {
			$(this).on('click', function() {
				var $current_selector = $(this).parents('.CountrySelector'),
					$parent = $(this).parent(),
					$h1_span = $current_selector.find('h1').find('span'),
					$countries = $(this).parents('.country_list').find('.country');
				if ($parent.hasClass('active_country')) {
					$countries.show();
					$parent.removeClass('active_country');
					$current_selector.parent().removeClass('wide');
					$h1_span.text('Region');
					console.log('Active');

				} else {
					$countries.hide();
					$parent.show().addClass('active_country');
					if ($parent.hasClass('wide_country')) {
						$current_selector.parent().addClass('wide');
					}
					$h1_span.text('Country');
				}
			});
		});

		var $filter_toggle = $('.filter_toggle');
		$filter_toggle.on('click', function(e) {
			e.preventDefault();
			$(this).parents('.filtering_wrap').toggleClass('open');
		});
	}); //end document ready

	function getBackgroundVideo($element) {
		var $ = jQuery,
			$videos = window.background_videos,
			$video_elem = $element.find('video');

		for (var i = $videos.length - 1; i >= 0; i--) {
			var source = document.createElement('source');
			source.src = $videos[i].src;
			source.type = $videos[i].type;
			$video_elem.append(source);
		}

	}

	function setVideoEvents($element) {
		var $ = jQuery,
			$video_elem = $element.find('video');
		$video_elem.on('loadeddata', function(e) {
			$element.removeClass('hide');
			$(this).get(0).play();
		});
	}

	function setHeroVideoDimensions($element) {
		if ($element.length < 1) {
			return;
		}
		var $ = jQuery,
			$parent_hero = $element.parents('.hero'),
			$video = $element.find('video'),
			$parent_width = $parent_hero.width(),
			$parent_height = $parent_hero.height(),
			$ratio = ($parent_width / $parent_height),
			$video_height = $parent_height * $ratio;
		$element.css({
			width: $parent_width,
			height: $parent_height
		});
		if ($ratio > (16 / 9)) {
			$video.css({
				width: $parent_width,
				height: $video_height,
				'margin-left': 0,
				'margin-top': ($video_height - $parent_height) / -2
			});
		} else {
			$video.css({
				width: $parent_width * (16 / 9),
				height: $video_height,
				'margin-left': (($parent_width * (16 / 9)) - $parent_width) / -2,
				'margin-top': 0
			});
		}
	}


	var $video_icon = $('.video_icon');
	if ($video_icon.length) {
		var tag = document.createElement('script');
		tag.src = "//www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		$video_icon.on("click", function() {

			var $this = $(this),
				$youtube_id = $(this).data('youtubeid'),
				$video_id = $(this).data('videoid'),
				$parent = $(this).parents('.video_function'),
				player = new YT.Player($video_id, {
					height: $parent.outerHeight(),
					width: $parent.outerWidth(),
					videoId: $youtube_id,
					events: {
						'onReady': function(event) {
							event.target.playVideo();
						}
					}
				});
			if ($($this).parents('.athlete__featured-video').length) {
				$this.fadeOut(300);
			}
		});
	}
})(jQuery);